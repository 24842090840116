import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueDayjs from 'vue-dayjs-plugin'
import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip'
import VueAnalytics from 'vue-analytics'
import VueWindowSize from 'vue-window-size'

Vue.config.productionTip = false

Vue.use(VueWindowSize)
Vue.use(VueDayjs)
Vue.use(VModal)
Vue.use(VTooltip)
Vue.use(VueAnalytics, {
  id: 'UA-78298612-3',
  router
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
