<template>
  <div
  class="simplifiedWord"
  :class="difficulty">
    <div class="word">{{word | obscure}}</div>
  </div>

</template>

<script>

export default {
  name: 'SimplifiedWord',
  props: ['word', 'difficulty', 'roundActive'],
  data () {
    return {
    }
  },
  filters: {
    obscure: function (value) {
      if (!value) return ''
      let newValue = value[0]
      for (let i = 1; i < value.length; i++) { // Starting at 1 to skip the first letter
        newValue = `${newValue}•`
      }
      return newValue
    }
  }

}
</script>

<style lang="scss" scoped>

.simplifiedWord {
  display: inline-flex;
  padding: 4px 6px;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-family: $fontstack;
  font-weight: 700;
  letter-spacing: 1px;
  background: $easy;
  color: $white;
  border-radius: 4px;
  font-size: 10px;

  &.hard {background: $hard;}
  &.impossible {background: $impossible;}

  .word {
    text-shadow: 1px 1px 2px #0000004a;
  }
}
</style>
