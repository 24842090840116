<template>
  <div
  v-if="roundActive"
  class="submittedWord active"
  :class="difficulty">
    <div class="word">{{word}}</div>
  </div>

  <div
  v-else
  class="submittedWord"
  :class="difficulty">
    <div class="word">{{word}}</div>
  </div>

</template>

<script>

export default {
  name: 'SubmittedWord',
  props: ['word', 'difficulty', 'roundActive'],
  data () {
    return {
    }
  }

}
</script>

<style lang="scss" scoped>

.submittedWord {
  // cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontstack;
  font-weight: 700;
  transition: $transition;

  &.hard {
    .word {border-color: $hard; background: $hard;}
  }

  &.impossible {
    .word {border-color: $impossible; background: $impossible;}
  }

  &.active {
    filter: none;

    .word {
      border-radius: $radius;
      background: transparent;
      color: $white;
    }

    &.hard {
      .word {border-color: $hard;}
    }

    &.impossible {
      .word {border-color: $impossible;}
    }

  }

  .word {
    border-radius: $radius;
    background: $easy;
    color: $white;
    display: flex;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: $easy;
    transition: $transition;
    font-size: 14px;
    padding: 4px;
    text-shadow: 2px 1px 6px #0000003d;
    letter-spacing: 0.1px;
    font-weight: 600;
  }

  @media (max-width: 779px) {
    .word {
      font-size: 14px;
      padding: 4px 6px;
    }
  }
}

</style>
